import React, { useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import '../../../../stories/widget/grid/ag-grid-theme-custom.css';
import {
  AgGridEvent,
  ColDef,
  RowSelectedEvent,
  SelectionChangedEvent,
} from 'ag-grid-community';
import '../../../../stories/widget/grid/grid.css';

export interface GridProps<T> {
  columnDefs: ColDef<T>[];
  rowData: T[];
  height?: number;
  width?: number;
  rowSelection?: 'single' | 'multiple';
  pagination?: boolean;
  headerHeight?: number;
  noRowsText?: string;
  loading?: boolean;
}

export const Grid = <T,>({
  rowData,
  columnDefs,
  height,
  width,
  rowSelection = 'single',
  pagination = false,
  headerHeight,
  noRowsText,
  loading = false,
}: GridProps<T>) => {
  const onRowSelected = (event: any) => {
    event.preventDefault;
    if (event.node.selected) {
      alert('Row Selected !!!');
      console.log('Row Select');
    }
  };

  return (
    <div style={{ height, width }}>
      <AgGridReact
        gridOptions={{
          ...(noRowsText && {
            localeText: {
              noRowsToShow: noRowsText,
            },
          }),
          ...(loading && {
            overlayLoadingTemplate: `
              <div class="flex items-center justify-center w-full">
                <div
                  class="inline-block animate-spin text-primary rounded-full border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite] h-6 w-6 border-2"
                  role="status">
                </div>
              </div>
            `,
          }),
        }}
        rowData={rowData}
        columnDefs={columnDefs}
        rowSelection={rowSelection}
        pagination={pagination}
        headerHeight={headerHeight}
        // onSelectionChanged={onSelectionChanged} // 선택 변경 이벤트
        onRowSelected={onRowSelected} // 개별 행 선택 이벤트
        loading={loading}
      ></AgGridReact>
    </div>
  );
};
