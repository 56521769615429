import { useEffect, useRef } from 'react';

export type TCoordinates = { x: number; y: number };

export interface RobotInfo {
  coordinate: TCoordinates;
  robotStateColor: string;
  shadow: boolean;
  nameKr?: string;
  robotStateString?: string;
}

interface UseCanvasProps {
  robotInfo: RobotInfo[];
  imageSrc: string;
  multiple: number;
}

const INITIAL_POSITION = { x: 0, y: 0 };

const useCanvas = ({ robotInfo, imageSrc, multiple }: UseCanvasProps) => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const scaleRef = useRef<number>(1);
  const viewPosRef = useRef<TCoordinates>(INITIAL_POSITION);
  const imageRef = useRef<HTMLImageElement | null>(null);

  useEffect(() => {
    if (typeof window === 'undefined') return;

    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    const image = new Image();
    imageRef.current = image;
    image.src = imageSrc;
    image.crossOrigin = 'Anonymous';

    const drawCoordinates = (
      ctx: CanvasRenderingContext2D,
      coordinates: TCoordinates,
      color: string,
      shadow: boolean,
      name?: string,
    ) => {
      // 그림자 설정
      if (shadow) {
        ctx.shadowColor = getCircleColor(color); // 그림자 색상
        ctx.shadowBlur = 30; // 그림자의 퍼짐 정도
        ctx.shadowOffsetX = 0; // X축 그림자 오프셋
        ctx.shadowOffsetY = 0; // Y축 그림자 오프셋
      }

      // 원(마커) 그리기
      ctx.fillStyle = getCircleColor(color);
      ctx.beginPath();
      ctx.arc(
        coordinates.x * multiple,
        coordinates.y * multiple,
        5 * multiple,
        0,
        2 * Math.PI,
      );
      ctx.fill();

      // 그림자 초기화 (텍스트에 그림자가 적용되지 않도록)
      ctx.shadowColor = 'transparent'; // 그림자 초기화
      ctx.shadowBlur = 0;
      ctx.shadowOffsetX = 0;
      ctx.shadowOffsetY = 0;

      if (name) {
        ctx.fillStyle = '#fff';
        const fontSize = 6 * multiple;
        ctx.font = `${fontSize}px Arial`;

        const textWidth = ctx.measureText(name).width;

        // 텍스트 테두리 그리기 (검정색 테두리)
        ctx.strokeStyle = 'black';
        ctx.lineWidth = 2; // 테두리 두께
        ctx.strokeText(
          name,
          coordinates.x * multiple - textWidth / 2, // 텍스트 중앙 정렬
          coordinates.y * multiple + 15 * multiple, // 텍스트 원 아래 배치
        );

        // 텍스트 채우기 (흰색 글자)
        ctx.fillStyle = '#fff';
        ctx.fillText(
          name,
          coordinates.x * multiple - textWidth / 2,
          coordinates.y * multiple + 15 * multiple,
        );
      }
    };

    const getCircleColor = (color: string) => {
      switch (color) {
        case 'RED':
          return '#FF4040';
        case 'GREEN':
          return '#26BFA1';
        case 'ORANGE':
          return '#FAAD14';
        case 'GRAY':
          return '#9ca3af';
        default:
          return '#9ca3af';
      }
    };

    const setTransform = (ctx: CanvasRenderingContext2D) => {
      ctx.setTransform(
        scaleRef.current,
        0,
        0,
        scaleRef.current,
        viewPosRef.current.x,
        viewPosRef.current.y,
      );
    };

    image.onload = () => {
      // 이미지가 로드된 후에만 캔버스 그리기 작업을 실행
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      setTransform(ctx); // 처음에만 변환 설정

      if (imageRef.current) {
        ctx.drawImage(imageRef.current, 0, 0, canvas.width, canvas.height);
      }

      // 모든 로봇 좌표를 그림
      robotInfo.forEach((info) =>
        drawCoordinates(
          ctx,
          info.coordinate,
          info.robotStateColor,
          info.shadow,
          info.nameKr,
        ),
      );
    };
  }, [imageSrc, robotInfo]);

  return { canvasRef };
};

export default useCanvas;
