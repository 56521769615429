import { ChangeEvent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import '../../style/calendar.css';

import useUserStore from '../../store/userStore';
import usePeriod, { dateFormat, getToday } from '../../hooks/usePeriod';
import useRobotData from '../../hooks/useRobotData';
import Empty from '../empty';
import Spinner from '../loading/Spinner';
import wariningIcon from '../../assets/icon/icon_warning.svg';
import {
  getCompleteTaskData,
  getCompleteTaskDataById,
  getTaskErrorCode,
  postCompleteTaskErrorStatement,
} from '../../_api/rest/history';
import {
  completeTaskType,
  completeTaskParams,
  selectOptionType,
  TASK_ERROR_TYPES,
  completeTaskExcelType,
} from '../../_types/history';
import { PeriodType } from '../../_types/dashBoard';

import { Trans, useTranslation } from 'react-i18next';
import i18next from 'i18next';
import moment from 'moment';
import { TbRefresh } from 'react-icons/tb';
import { toast } from 'react-toastify';
import { Button, Modal, Select } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import Datepicker, { DateValueType } from 'react-tailwindcss-datepicker';
import { FaPenToSquare } from 'react-icons/fa6';
import { SiMicrosoftexcel } from 'react-icons/si';
import { excelDownload } from '../../util/excelUtil';

import dayjs from 'dayjs';

const Finished = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { selectedUserAuthSite } = useUserStore();
  const { robotSocketData } = useRobotData();

  const { startDate, endDate } = usePeriod();
  const lastMonth = new Date();
  lastMonth.setMonth(lastMonth.getMonth() - 1);
  lastMonth.setDate(lastMonth.getDate() + 1);

  const [completeTaskData, setCompleteTaskData] = useState<completeTaskType[]>(
    [],
  );
  const [completeTaskExcelData, setCompleteTaskExcelData] = useState<
    completeTaskExcelType[]
  >([]);

  const [selectRobotType, setSelectRobotType] = useState<string>('');
  const [selectRobotName, setSelectRobotName] = useState<string>('');
  const [selectErrorType, setSelectErrorType] = useState<string>('');
  const [isShowErrLogModal, setIsShowErrLogModal] = useState<boolean>(false);
  const [selectedTask, setSelectedTask] = useState<completeTaskType | null>(
    null,
  );

  const [errorStatement, setErrorStatement] = useState<string | null>(null);
  const [errorCodeList, setErrorCodeList] = useState<selectOptionType[]>([]);
  const [errorCode, setErrorCode] = useState<string | null>(null);

  const [period, setPeriod] = useState<PeriodType>({
    startDate: dateFormat(lastMonth),
    endDate: getToday(),
  });

  const [robotTypeFilter, setRobotTypeFilter] = useState<selectOptionType[]>(
    [],
  );

  const [refreshing, setRefreshing] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [lastUpdated, setLastUpdated] = useState<string>('');

  const fetchCompleteTaskData = async () => {
    setLoading(true);
    try {
      const params: completeTaskParams = {
        siteIds: selectedUserAuthSite.join(','),
        startDate: period.startDate as string,
        endDate: period.endDate as string,
        ...(selectErrorType && { alarmType: selectErrorType }),
        ...(selectRobotType && { robotModelTypeEn: selectRobotType }),
      };

      const data = await getCompleteTaskData(params);
      setCompleteTaskData(data);
      setTimeout(() => setLoading(false), 800);
      setLastUpdated(moment().format('YYYY. M. D. HH:mm:ss'));
    } catch (error) {
      toast.error(`${error}`);
    }
  };

  const fetchTaskErrorCode = async () => {
    try {
      const data = await getTaskErrorCode();
      setErrorCodeList(
        data.map((item) => {
          return {
            value: item.name,
            label: item.statement,
          };
        }),
      );
    } catch (error) {
      toast.error(`${error}`);
    }
  };

  useEffect(() => {
    if (robotSocketData.length > 0) {
      const types = robotSocketData.map((item) => ({
        label: item.modelType,
        value: item.modelTypeEn,
      }));

      const filterTypes = [
        ...new Set(types.map((type) => JSON.stringify(type))),
      ].map((type) => JSON.parse(type));

      setRobotTypeFilter(filterTypes);
    }
  }, [robotSocketData]);

  useEffect(() => {
    setPeriod({ startDate, endDate });
  }, [startDate, endDate]);

  const handleSelectErrorType = (event: ChangeEvent<HTMLSelectElement>) => {
    setSelectErrorType(event.target.value);
  };

  const handleSelectRobotType = (event: ChangeEvent<HTMLSelectElement>) => {
    setSelectRobotType(event.target.value);
  };

  const handleChangeRobotName = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectRobotName(event.target.value);
  };

  const handleChangeDate = (newValue: DateValueType) => {
    navigate(`/history?start=${newValue?.startDate}&end=${newValue?.endDate}`);
  };

  const handleClickRefresh = async () => {
    setRefreshing(true);

    await fetchCompleteTaskData();
    setTimeout(() => {
      setRefreshing(false);
    }, 400);
  };

  const handleTaskSelect = async ({ alarmType, id }: completeTaskType) => {
    if (alarmType !== 'ERROR') return;

    try {
      const selectedTask = await getCompleteTaskDataById(id);

      setSelectedTask(selectedTask);
      setErrorStatement(selectedTask.errorStatement || null);
      setErrorCode(selectedTask.errorCode?.name || null);
      setIsShowErrLogModal(true);
    } catch (error) {
      toast.error(`${error}`);
    }
  };

  const handleErrLogModalOk = async () => {
    try {
      const taskData = {
        ...selectedTask,
        errorStatement: errorStatement || null,
        errorCode: {
          name: errorCode,
          statement: errorCodeList.filter((item) => item.value === errorCode)[0]
            .label,
        },
      };

      const res = await postCompleteTaskErrorStatement(
        taskData as completeTaskType,
      );
      if (res === 'SUCCESS') {
        toast.success(t('Error_Log_Save_Success'));
        setIsShowErrLogModal(false);
        fetchCompleteTaskData();
      } else {
        toast.error(t('Error_Log_Save_Fail'));
      }
    } catch (error) {
      toast.error(`${error}`);
    }
  };

  const handleErrLogModalCancel = () => {
    setIsShowErrLogModal(false);
  };

  const handleExcelDownload = () => {
    if (completeTaskExcelData.length === 0) {
      return toast.error('다운로드할 데이터가 없습니다.');
    }

    excelDownload(
      completeTaskExcelData,
      `완료된작업 ${dayjs().format('YYYY-MM-DD_HHmm')}.xlsx`,
    );
  };

  useEffect(() => {
    if (!isShowErrLogModal) {
      setSelectedTask(null);
      setErrorStatement(null);
      setErrorCode(null);
    }
  }, [isShowErrLogModal]);

  useEffect(() => {
    if (selectedUserAuthSite.length === 0) return;

    fetchCompleteTaskData();
  }, [selectedUserAuthSite, period, selectErrorType, selectRobotType]);

  useEffect(() => {
    setCompleteTaskExcelData(
      completeTaskData.map((item) => {
        return {
          시작시간: new Date(item.startTime).toLocaleString(),
          종료시간: new Date(item.lastUpdateTime).toLocaleString(),
          소요시간: item.ellapsedTimeTotal.split('.')[0],
          작업명: item.taskName,
          로봇타입: item.robotModelTypeList.join(', '),
          로봇명: item.robotNameList.join(', '),
          알람구분: item.alarmType,
          세부내용: item.taskStateAlias,
          '에러 세부내용': item.errorStatement,
          완료여부: item.completeStatus,
        };
      }),
    );
  }, [completeTaskData]);

  useEffect(() => {
    fetchTaskErrorCode();
  }, []);

  return (
    <div>
      <section className="flex items-center gap-5 pb-3" data-name="bg">
        <>
          <select
            className="w-full min-w-[130px] max-w-[200px] px-3 py-[8px] text-sm border border-white rounded-lg shadow-sm"
            value={selectErrorType}
            onChange={handleSelectErrorType}
            data-name="robotType"
          >
            <option hidden>에러 타입 선택</option>
            <option value="">{t('error_types_all')}</option>
            {TASK_ERROR_TYPES.map((item: (typeof TASK_ERROR_TYPES)[number]) => {
              return (
                <option key={item} value={item}>
                  {item}
                </option>
              );
            })}
          </select>
          <select
            className="w-full min-w-[130px] max-w-[200px] px-3 py-[8px] text-sm border border-white rounded-lg shadow-sm"
            value={selectRobotType}
            onChange={handleSelectRobotType}
            data-name="robotType"
          >
            <option hidden>로봇 타입 선택</option>
            <option value="">{t('robot_types_all')}</option>
            {robotTypeFilter.length !== 0 &&
              robotTypeFilter.map((item: selectOptionType) => {
                return (
                  <option key={item.value} value={item.value}>
                    {item.label}
                  </option>
                );
              })}
          </select>

          <input
            className="w-full min-w-[130px] max-w-[200px] px-3 py-2 text-sm border border-white rounded-lg shadow-sm"
            type="text"
            placeholder={t('robot_name_searching')}
            value={selectRobotName}
            onChange={handleChangeRobotName}
          />

          <div className="min-w-[300px] datepicker-notification">
            <Datepicker
              value={period}
              inputClassName={
                'w-full  py-2 px-3 text-sm focus:bg-[#ebf7f6] placeholder:text-sm rounded-lg sm:text-sm shadow-sm '
              }
              placeholder={t('please_select_a_period')}
              primaryColor={'teal'}
              useRange={false}
              showShortcuts={true}
              onChange={handleChangeDate}
              i18n={`${i18next.resolvedLanguage}`}
              dateLooking="middle"
              configs={{
                shortcuts: {
                  today: `${t('today')}`,
                  yesterday: `${t('yesterday')}`,
                  currentMonth: `${t('currentMonth')}`,
                  pastMonth: `${t('pastMonth')}`,
                  past: (period) => `${t('the_last')} ${period}${t('days')}`,
                },
              }}
            />
          </div>

          <Button
            className="flex items-center justify-center float-end buttonSwitch"
            onClick={handleExcelDownload}
            type="primary"
            icon={<SiMicrosoftexcel />}
          >
            {t('download')}
          </Button>

          <div className="pr-3 min-w-[400px] mt-[5px] flex justify-end ml-auto">
            <span className="mt-[5px]">
              {t('last_update')} : {lastUpdated}
            </span>

            <div className="ml-3">
              <Button
                onClick={handleClickRefresh}
                disabled={period.startDate === null}
                type="primary"
                ghost
              >
                <TbRefresh
                  size="24"
                  color={period.startDate === null ? '#D9D9D9' : '#08B295'}
                  className={refreshing ? 'animate-spin-fast' : ''}
                />
              </Button>
            </div>
          </div>
        </>
      </section>
      <div className="pt-3 pb-7">
        <div id="my-table" className="text-center text-sm w-[100%]">
          {!loading && !refreshing ? (
            completeTaskData.length > 0 ? (
              <>
                <div className="flex items-center font-bold text-center shadow-sm bg-neutral-200 rounded-xl">
                  <div className="px-2 py-3 w-[15%]">
                    <Trans i18nKey="Time" />
                  </div>
                  <div className="py-3 w-[10%]">
                    <Trans i18nKey="time_required" />
                  </div>
                  <div className="py-3 w-[20%]">
                    <Trans i18nKey="task_name" />
                  </div>
                  <div className="py-3 w-[5%]">
                    <Trans i18nKey="robot_type" />
                  </div>
                  <div className="py-3 w-[15%]">
                    <Trans i18nKey="robot_name" />
                  </div>
                  <div className="py-3 w-[5%]">
                    <Trans i18nKey="alarm_classification" />
                  </div>
                  <div className="py-3 w-[20%]">
                    <Trans i18nKey="details" />
                  </div>
                  <div className="py-3 w-[10%]">
                    <Trans i18nKey="completion_status" />
                  </div>
                </div>
                {completeTaskData.map(
                  (item: completeTaskType, idx: number) =>
                    item.robotNameList.some((name: string) =>
                      name
                        .toLowerCase()
                        .includes(selectRobotName.toLowerCase()),
                    ) && (
                      <div
                        key={item.taskId}
                        onClick={() => handleTaskSelect(item)}
                      >
                        <div
                          className={`flex items-center bg-white hover:cursor-pointer hover:bg-[#d3ece8] ${
                            localStorage.login === 'true' &&
                            localStorage.verified === 'true'
                              ? 'justify-center mt-1 rounded-xl shadow-sm'
                              : 'blur-sm '
                          }`}
                        >
                          <div className="py-3 w-[15%]">
                            <span className="block">
                              {new Date(item.startTime).toLocaleString()}
                            </span>
                            <span className="block">
                              {new Date(item.lastUpdateTime).toLocaleString()}
                            </span>
                          </div>
                          <div className="py-3 w-[10%]">
                            {item.ellapsedTimeTotal.split('.')[0]}
                          </div>
                          <div className="w-[20%] flex justify-center">
                            {item.taskName}
                          </div>

                          <div key={item.taskId} className="py-3 w-[5%]">
                            {item.robotModelTypeList.map(
                              (type: string, idx: number) => (
                                <span className="block" key={idx}>
                                  {type}
                                </span>
                              ),
                            )}
                          </div>

                          <div className="py-3 w-[15%]">
                            {item.robotNameList.map(
                              (name: string, idx: number) => (
                                <span className="block" key={idx}>
                                  {name}
                                </span>
                              ),
                            )}
                          </div>

                          <div
                            className={`py-3 w-[5%] ${
                              item.alarmType === 'ERROR' ? 'text-red-500' : ''
                            }`}
                          >
                            {item.alarmType}
                          </div>
                          <div className="py-3 w-[20%] flex justify-center items-center">
                            {item.alarmType === 'ERROR' &&
                              !item.errorStatement && (
                                <FaPenToSquare
                                  color={'#08b295'}
                                  className="mt-0.5 mr-2"
                                />
                              )}
                            <span
                              className={`${item.alarmType === 'ERROR' ? `underline underline-offset-[5px]` : ''}`}
                            >
                              {item.taskStateAlias}
                            </span>
                          </div>
                          <div
                            className={`py-3 w-[10%] ${
                              item.completeStatus === '비정상'
                                ? 'text-red-500'
                                : ''
                            }`}
                          >
                            {item.completeStatus}
                          </div>
                        </div>
                      </div>
                    ),
                )}
              </>
            ) : (
              <div className="flex items-center justify-center w-full pt-[80px]">
                <Empty
                  mainText={t('There_are_no_completed_works')}
                  subText={t(
                    'The_selected_site_does_not_have_any_completed_works_during_the_specified_period',
                  )}
                >
                  {<img src={wariningIcon} className="w-[48px] py-4" />}
                </Empty>
              </div>
            )
          ) : (
            <div className="mt-[250px]">
              <Spinner loading={loading} />
            </div>
          )}
        </div>
      </div>

      <Modal
        title={t('Save_Error_Log')}
        open={isShowErrLogModal}
        okText={t('confirm')}
        cancelText={t('cancel')}
        onOk={handleErrLogModalOk}
        okButtonProps={{ disabled: !errorStatement || !errorCode }}
        onCancel={handleErrLogModalCancel}
        style={{ top: '20%' }}
      >
        <p className="pt-[10px] pb-[5px]">에러코드 선택</p>
        <Select
          placeholder="에러를 선택해주세요"
          style={{ width: '100%' }}
          value={errorCode}
          onChange={(e) => setErrorCode(e)}
          options={errorCodeList}
        />

        <p className="mt-[30px] pb-[5px]">에러 로그 입력</p>
        <TextArea
          rows={3}
          onChange={(e) => setErrorStatement(e.target.value)}
          value={errorStatement ?? ''}
          style={{ marginBottom: '10' }}
        />
      </Modal>
    </div>
  );
};

export default Finished;
