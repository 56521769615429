import { MdLocationOn } from 'react-icons/md';
import { Badge, Button, Card, FloatButton, Select } from 'antd';
import MapCard from '../../map/robot/MapCard';
import { useSuspenseQuery } from '@tanstack/react-query';
import { getFloorBySiteId, getStandardMap } from '../../../_api/rest/map';
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { StandardMap } from '../../../_types/map';
import { io } from 'socket.io-client';
import { SECOND_SOCKET_URL } from '../../../_api/webSocket';
import { DeviceDataType } from '../../../_types';
import { RobotOutlined, DoubleRightOutlined } from '@ant-design/icons';
import { getRobotStateColor } from '../../../util/styleString';

interface props {
  selectedSite: string;
}

const MapContainer = forwardRef<HTMLCanvasElement, props>(
  ({ selectedSite }: props, ref) => {
    const [selectedFloorIndex, setSelectedFloorIndex] = useState<string>('');
    const [robotList, setRobotList] = useState<DeviceDataType[]>([]);
    const [open, setOpen] = useState<boolean>(false);
    const [selectedRobot, setSelectedRobot] = useState<string>('');
    const [currentRobotInof, setCurrentRobotInfo] = useState<
      | {
          [key in 'color' | 'name' | 'state']: string;
        }
      | null
    >(null);
    const [hasOnline, setHasOnline] = useState<boolean>(false);

    const canvasRef = useRef<HTMLCanvasElement | null>(null);

    useImperativeHandle(ref, () => canvasRef.current!);

    useEffect(() => {
      const socket = io(`${SECOND_SOCKET_URL}/multi-robots-on-map`);

      socket.on('startMultiRobotsDatasOnMapGateway', () => {
        console.log('startMultiRobotsDatasOnMapGateway ');
      });

      if (!selectedSite || !selectedFloorIndex) return;
      socket.emit(
        'request',

        {
          siteId: selectedSite,
          floorIndex: selectedFloorIndex,
        },
      );
      socket.on('robots', (data: DeviceDataType[]) => {
        setRobotList(data);
      });

      return () => {
        socket.disconnect();
        socket.off('startMultiRobotsDatasOnMapGateway');
        socket.off('robots');
      };
    }, [selectedSite, selectedFloorIndex]);

    const { data: floorList, isSuccess: floorSuccess } = useSuspenseQuery({
      queryKey: ['floor', selectedSite],
      queryFn: () => {
        if (!selectedSite) return [];
        return getFloorBySiteId(selectedSite);
      },
    });

    const { data: standardMap } = useSuspenseQuery({
      queryKey: ['standard-map', selectedSite, selectedFloorIndex],
      queryFn: () => {
        if (!selectedSite || !selectedFloorIndex) return {} as StandardMap;
        return getStandardMap(selectedSite, selectedFloorIndex);
      },
    });

    useEffect(() => {
      if (floorSuccess) setSelectedFloorIndex(floorList?.[0]?.name || '');
    }, [floorSuccess, floorList]);

    useEffect(() => {
      const hasOnlineRobot = robotList.some((robot) => robot.isOnline);

      const currentRobot = robotList.filter(
        (robot) => robot.name === selectedRobot,
      )?.[0];
      setHasOnline(hasOnlineRobot);
      setCurrentRobotInfo({
        name: currentRobot?.name || robotList?.[0]?.name,
        color: currentRobot?.robotStateColor || robotList?.[0]?.robotStateColor,
        state:
          currentRobot?.robotStateString || robotList?.[0]?.robotStateString,
      });
    }, [robotList, selectedRobot]);

    useEffect(() => {
      if (ref && canvasRef.current) {
        (ref as React.MutableRefObject<HTMLCanvasElement>).current =
          canvasRef.current;
      }
    }, [ref, canvasRef]);

    return (
      <div className="flex flex-col items-start w-full gap-5">
        <div className="flex items-center justify-start w-full h-full gap-1 px-2 pt-5 ">
          <MdLocationOn />
          <Select
            className="w-[180px]"
            value={selectedFloorIndex}
            options={
              floorList.length > 0
                ? floorList?.map((el) => ({
                    key: el.id,
                    label: el.nameKr,
                    value: el.name,
                  }))
                : []
            }
            placeholder={floorList.length === 0 && '-'}
            disabled={floorList.length === 0}
            onChange={(floor) => {
              setOpen(false);
              setSelectedFloorIndex(floor);
            }}
          />
        </div>
        <div className="relative w-full">
          {standardMap?.publicMapUrl && (
            <>
              {open ? (
                <div className="absolute right-5 bottom-5 z-[19] ">
                  <Card
                    size="small"
                    className="flex flex-col gap-4 transition-transform duration-500 ease-in-out shadow-md"
                  >
                    <div className="flex items-center gap-2">
                      <Select
                        className="w-[200px]"
                        options={robotList?.map((robot) => ({
                          label: robot.name,
                          value: robot.name,
                        }))}
                        onChange={(value) => setSelectedRobot(value)}
                        value={selectedRobot || robotList?.[0]?.name}
                      />
                      <Button
                        onClick={() => setOpen(false)}
                        className=""
                        icon={<DoubleRightOutlined rotate={90} />}
                      />
                    </div>

                    <div className={`flex gap-1 pt-2 text-sm`}>
                      <span
                        className={`${getRobotStateColor(currentRobotInof?.color || '')}`}
                      >
                        ●
                      </span>
                      <span>{currentRobotInof?.state || ''}</span>
                    </div>
                  </Card>
                </div>
              ) : (
                <>
                  <Badge
                    dot={hasOnline}
                    className="absolute right-[21px] bottom-[53px] z-[18]"
                  />
                  <FloatButton
                    className="absolute right-5 bottom-5 shadow-md hover:bg-white z-[17]"
                    shape="square"
                    icon={<RobotOutlined />}
                    onClick={() => setOpen(true)}
                  />
                </>
              )}
            </>
          )}

          <MapCard
            ref={canvasRef}
            robotInfo={robotList.map((robot) => ({
              coordinate: {
                x:
                  Math.abs(robot.standardLocation.mapX) ||
                  Math.abs(robot.standardLocation.x),
                y:
                  Math.abs(robot.standardLocation.mapY) ||
                  Math.abs(robot.standardLocation.y),
              },
              robotStateColor: robot.robotStateColor,
              shadow: robot.name === currentRobotInof?.name && open,
              nameKr: robot.name, // 2.0 소켓데이터 robot nameKr 존재 x
              robotStateString: robot.robotStateString,
            }))}
            imageSrc={standardMap?.publicMapUrl || ''}
            hasControls={false}
            multiple={2.5}
          />
        </div>
      </div>
    );
  },
);

export default MapContainer;
