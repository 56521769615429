import { ColumnChart } from '../../dashboard/common/chart/columnChart';
import { ApexOptions } from 'apexcharts';
import { TitleWidget } from '../../dashboard/common/label/titleWidget';
import { IoBarChart } from 'react-icons/io5';
import { RiExternalLinkFill } from 'react-icons/ri';
import { DateLabel } from '../../dashboard/common/label/dateLabel';
import { AiOutlineBarChart } from 'react-icons/ai';

const options: ApexOptions = {
  chart: {
    type: 'bar',
  },
  colors: ['#08b295'],
  plotOptions: {
    bar: {
      columnWidth: '50%',
      borderRadius: 3,
      dataLabels: {
        position: 'top', // top, center, bottom
      },
      distributed: true,
      // colors: {
      //   ranges: [
      //     {
      //       from: 500,
      //       to: 10000,
      //       color: '#FF4560', // 41부터 100까지의 범위는 이 색상으로 표시
      //     },
      //   ],
      // },
    },
  },
  dataLabels: {
    enabled: true,
    formatter: function (val) {
      return val + '건';
    },
    offsetY: -20,
    style: {
      fontSize: '12px',
      colors: ['#304758'],
    },
  },

  xaxis: {
    categories: ['Task1', 'Task2', 'Task3', 'Task4', 'Task5', 'Task6'],
    position: 'bottom',
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: true,
    },
    crosshairs: {
      fill: {
        type: 'gradient',
        gradient: {
          colorFrom: '#D8E3F0',
          colorTo: '#BED1E6',
          stops: [0, 100],
          opacityFrom: 0.4,
          opacityTo: 0.5,
        },
      },
    },
    tooltip: {
      enabled: true,
    },
  },
  yaxis: {
    tickAmount: 4,
    min: 0,
    max: function (max) {
      return max * 1.2;
    },
    axisTicks: {
      show: false,
    },
    labels: {
      show: true,
      formatter: function (val) {
        return val + '건';
      },
    },
  },
};

const series = [
  {
    name: '작업 건수',
    data: [77, 100, 40, 30, 100, 200],
  },
];

const DailyWorkDistributionChart = () => {
  return (
    <div className={'p-5'}>
      <div className={'flex border-b-[1px] border-gray-100'}>
        <div className={'content-center'}>
          <TitleWidget
            fontSize={17}
            title={'일일 작업 분포'}
            icon={AiOutlineBarChart}
            iconPosition={'left'}
            iconSize={20}
            iconMarginL={10}
            padding={'0px 10px 10px 10px'}
            width={160}
            useLink={false}
          />
        </div>
        <div className={'ml-1 content-center'}>
          <DateLabel
            fontSize={11}
            dateFormat={'YYYY-MM-DD HH:mm:ss'}
            useClock={false}
          />
        </div>
        <div className={'content-center ml-auto'}>
          <TitleWidget
            fontSize={13}
            title={'더보기'}
            icon={RiExternalLinkFill}
            iconPosition={'left'}
            iconSize={16}
            iconMarginL={3}
            padding={'0px 10px 10px 10px'}
            useLink={true}
            linkAddress={'/dashboard'}
          />
        </div>
      </div>
      <div className={'text-[24px] p-3'}>총 100건 작업, 작업 성공률 90%</div>
      <div>
        <ColumnChart
          options={options}
          series={series}
          width={'100%'}
          height={260}
        />
      </div>
    </div>
  );
};

export default DailyWorkDistributionChart;
